var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"crud","fluid":"","tag":"section"}},[_c('v-card',[_c('v-data-iterator',{attrs:{"items":_vm.noticias,"items-per-page":3,"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-toolbar',{staticClass:"px-2"},[_c('v-col',{attrs:{"sm":"11"}},[_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"clearable":"","density":"comfortable","hide-details":"","placeholder":"Buscar","prepend-inner-icon":"mdi-magnify","variant":"solo"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-dialog',{attrs:{"max-width":"50%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.is('administrador'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":_vm.$cv('principal'),"dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"blue":""}},[_vm._v("mdi-plus")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{attrs:{"dark":"","color":_vm.$cv('principal')}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-toolbar-items',[(!_vm.vista)?_c('v-btn',{attrs:{"dark":"","text":"","disabled":!_vm.valid},on:{"click":function($event){return _vm.save()}}},[_vm._v("Guardar")]):_vm._e()],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"pa-0",attrs:{"label":"Asunto","disabled":_vm.vista,"rules":[
																_vm.$rulesRequerido,
																_vm.$rulesAlfaNum,
																_vm.$rulesMax500,
															]},model:{value:(_vm.editedItem.asunto),callback:function ($$v) {_vm.$set(_vm.editedItem, "asunto", $$v)},expression:"editedItem.asunto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"readonly":_vm.readonly,"label":"Mensaje","auto-grow":"","outlined":"","rows":"4","row-height":"30","rules":[_vm.$rulesRequerido],"dense":"","clearable":"","clear-icon":"mdi-close-circle"},model:{value:(_vm.editedItem.contenido),callback:function ($$v) {_vm.$set(_vm.editedItem, "contenido", $$v)},expression:"editedItem.contenido"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"default",fn:function({ items }){return [_c('v-container',{staticClass:"pa-2",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"auto","md":"4"}},[_c('v-card',{staticClass:"pb-1 elevation-1",staticStyle:{"min-height":"300px"},attrs:{"border":"","flat":""}},[_c('v-img',{attrs:{"src":item.archivo_adjunto
												? item.archivo_adjunto
												: 'https://www.acara.org.ar/img/img_acara_news.jpg',"height":"150"}}),_c('strong',{staticClass:"text-h3 mb-2 pl-2 pr-2"},[_vm._v(_vm._s(item.asunto))]),_c('p',{staticClass:"text-h6 mb-2 pl-2 pr-2"},[_vm._v(_vm._s(item.contenido))]),_c('div',{staticClass:"d-flex justify-space-between px-1"},[_c('div',{staticClass:"d-flex align-center text-caption text-medium-emphasis me-1"},[_c('v-icon',{staticClass:"mdi mdi-clock",attrs:{"start":""}}),_c('div',{staticClass:"px-1 text-truncate"},[_vm._v(" "+_vm._s(_vm.$formatDate(item.created_at))+" ")])],1)])],1)],1)}),1)],1)]}}])})],1),_c('v-snackbar',{attrs:{"bottom":true,"color":_vm.color,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('vue-confirm-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }