<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-data-iterator :items="noticias" :items-per-page="3" :search="search">
				<template v-slot:header>
					<v-toolbar class="px-2">
						<v-col sm="11">
							<v-text-field
								v-model="search"
								clearable
								density="comfortable"
								hide-details
								placeholder="Buscar"
								prepend-inner-icon="mdi-magnify"
								style="max-width: 300px"
								variant="solo"
							></v-text-field>
						</v-col>
						<v-col sm="1">
							<v-dialog v-model="dialog" max-width="50%" persistent>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-if="is('administrador')"
										:color="$cv('principal')"
										dark
										class="mx-2"
										fab
										v-bind="attrs"
										v-on="on"
										><v-icon blue>mdi-plus</v-icon></v-btn
									>
								</template>
								<v-card>
									<v-form ref="form" v-model="valid" lazy-validation>
										<v-toolbar dark :color="$cv('principal')">
											<v-btn icon dark @click="dialog = false">
												<v-icon>mdi-close</v-icon>
											</v-btn>
											<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
											<v-spacer></v-spacer>
											<v-toolbar-items>
												<v-btn
													dark
													text
													@click="save()"
													v-if="!vista"
													:disabled="!valid"
													>Guardar</v-btn
												>
											</v-toolbar-items>
										</v-toolbar>

										<v-card-text>
											<v-container>
												<!--<v-row>
													<v-col cols="12" md="12" sm="12" class="pa-0">
														<v-autocomplete
															:items="usuarios"
															:disabled="vista"
															item-value="id"
															item-text="username"
															label="Para"
															v-model="editedItem.para_id"
															:rules="[
																(v) =>
																	!!(v && v.length) ||
																	'Este campo es requerido',
															]"
															no-data-text="Este campo es requerido"
															class="pa-0"
														></v-autocomplete>
													</v-col>
												</v-row>-->
												<v-row>
													<v-col cols="12" md="12" sm="12" class="pa-0">
														<v-text-field
															v-model="editedItem.asunto"
															label="Asunto"
															:disabled="vista"
															:rules="[
																$rulesRequerido,
																$rulesAlfaNum,
																$rulesMax500,
															]"
															class="pa-0"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="12" sm="12" class="pa-0">
														<v-textarea
															v-model="editedItem.contenido"
															:readonly="readonly"
															label="Mensaje"
															auto-grow
															outlined
															rows="4"
															row-height="30"
															:rules="[$rulesRequerido]"
															dense
															clearable
															clear-icon="mdi-close-circle"
														></v-textarea>
													</v-col>
												</v-row>
											</v-container>
										</v-card-text>
									</v-form>
								</v-card>
							</v-dialog>
						</v-col>
					</v-toolbar>
				</template>

				<template v-slot:default="{ items }">
					<v-container class="pa-2" fluid>
						<v-row dense>
							<v-col v-for="item in items" :key="item.id" cols="auto" md="4">
								<v-card
									class="pb-1 elevation-1"
									border
									flat
									style="min-height: 300px"
								>
									<v-img
										:src="
											item.archivo_adjunto
												? item.archivo_adjunto
												: 'https://www.acara.org.ar/img/img_acara_news.jpg'
										"
										height="150"
									></v-img>

									<strong class="text-h3 mb-2 pl-2 pr-2">{{
										item.asunto
									}}</strong>
									<p class="text-h6 mb-2 pl-2 pr-2">{{ item.contenido }}</p>

									<div class="d-flex justify-space-between px-1">
										<div
											class="d-flex align-center text-caption text-medium-emphasis me-1"
										>
											<v-icon class="mdi mdi-clock" start></v-icon>

											<div class="px-1 text-truncate">
												{{ $formatDate(item.created_at) }}
											</div>
										</div>
									</div>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</v-data-iterator>
			<!--
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar>

			<v-data-table
				:headers="showHeaders"
				:items="filteredData"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:[`header.nombre`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.activo`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`item.estado`]="{ item }">
					<div :class="$getCss(item.estado, 'estado')" dark>
						{{ item.estado }}
					</div>
				</template>

				<template v-slot:top>
					<v-toolbar flat color="white" class="pr-4">
						<v-row>
							<v-col sm="6" class="pt-20">
								<v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									:label="'Busqueda de ' + title"
									single-line
									hide-details
								></v-text-field>
							</v-col>
							<v-col sm="2"></v-col>
							<v-col sm="3" class="">
								<v-autocomplete
									v-model="selectedHeaders"
									:items="headers"
									label="Columnas Visibles"
									multiple
									return-object
								>
									<template v-slot:selection="{ item, index }">
										<v-chip v-if="index < 2">
											<span>{{ item.text }}</span>
										</v-chip>
										<span v-if="index === 2" class="grey--text caption"
											>(otras {{ selectedHeaders.length - 2 }}+)</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col sm="1">
								<v-dialog v-model="dialog" max-width="50%" persistent>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-if="is('administrador')"
											:color="$cv('principal')"
											dark
											class="mx-2"
											fab
											v-bind="attrs"
											v-on="on"
											><v-icon blue>mdi-plus</v-icon></v-btn
										>
									</template>
									<v-card>
										<v-form ref="form" v-model="valid" lazy-validation>
											<v-toolbar dark :color="$cv('principal')">
												<v-btn icon dark @click="dialog = false">
													<v-icon>mdi-close</v-icon>
												</v-btn>
												<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
												<v-spacer></v-spacer>
												<v-toolbar-items>
													<v-btn
														dark
														text
														@click="$save()"
														v-if="!vista"
														:disabled="!valid"
														>Guardar</v-btn
													>
												</v-toolbar-items>
											</v-toolbar>

											<v-card-text>
												<v-container>
													<v-row>
														<v-col cols="12" md="12" sm="12" class="pa-0">
															<v-autocomplete
																:items="usuarios"
																:disabled="vista"
																item-value="id"
																item-text="username"
																label="Para"
																v-model="editedItem.para_id"
																:rules="[
																	(v) =>
																		!!(v && v.length) ||
																		'Este campo es requerido',
																]"
																no-data-text="Este campo es requerido"
																class="pa-0"
															></v-autocomplete>
														</v-col>
													</v-row>
													<v-row>
														<v-col cols="12" md="12" sm="12" class="pa-0">
															<v-text-field
																v-model="editedItem.asunto"
																label="Asunto"
																:disabled="vista"
																:rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax500,
																]"
																class="pa-0"
															></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col cols="12" md="12" sm="12" class="pa-0">
															<v-textarea
																v-model="editedItem.contenido"
																:readonly="readonly"
																label="Mensaje"
																auto-grow
																outlined
																rows="4"
																row-height="30"
																:rules="[$rulesRequerido]"
																dense
																clearable
																clear-icon="mdi-close-circle"
															></v-textarea>
														</v-col>
													</v-row>
												</v-container>
											</v-card-text>
										</v-form>
									</v-card>
								</v-dialog>
							</v-col>
						</v-row>
					</v-toolbar>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						small
						:color="$cv('btnEditar')"
						title="Editar"
						@click="
							vista = false;
							editItem(item);
						"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
    -->
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Noticias";
	}

	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			dessertActivo: "",
			route: "mensajes",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 500 || "Max 500 caracteres"],
			search: "",
			vista: false,
			users: false,
			headers: [
				{
					text: "Id",
					align: "start",
					sortable: false,
					value: "id",
				},

				{ text: "Para", value: "para_username" },
				{ text: "Asunto", filterable: true, value: "asunto" },
				{ text: "Estado", value: "estado" },
				{ text: "Fecha", value: "created_at" },

				{ text: "Acciones", value: "actions", sortable: false },
			],

			desserts: [],
			editedIndex: -1,

			editedItem: {
				para_id: [],
				asunto: "",
				id: "",
				tipo_mensaje: 2,
			},
			defaultItem: {
				para_id: [],

				asunto: "",
				id: "",
				tipo_mensaje: 2,
			},
			filters: {
				para_id: [],
				asunto: "",
				activo: "",
			},
			filterKey: [],
			selectedHeaders: [],
			readonly: false,
			usuarios: [],
			para_id: [],
			noticias: [],
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: "Editar " + this.title;
			},
			filteredData() {
				return this.$filteredData().data !== undefined
					? this.$filteredData().data
					: this.$filteredData();
			},
			showHeaders() {
				return this.headers.filter((s) => this.selectedHeaders.includes(s));
			},
		},

		watch: {
			dialog(val) {
				val || this.$close();
			},
		},

		created() {
			this.initialize();
			this.selectedHeaders = this.headers;
			this.$getData(["usuarios"]);
		},

		methods: {
			filterByNombre(item) {
				return this.$filterBy(item, "nombre");
			},
			filterByActivo(item) {
				return this.$filterBy(item, "activo");
			},
			formatDate(timestamp) {
				console.log(timestamp);
				if (timestamp === null) {
					return "";
				}

				const date = new Date(timestamp);
				const day = date.getDate().toString().padStart(2, "0");
				const month = (date.getMonth() + 1).toString().padStart(2, "0");
				const year = date.getFullYear();
				return `${day}/${month}/${year}`;
			},

			async editItem(item) {
				let vm = this;
				let id = item.id;

				if (item.estado == "enviado") {
					await vm.$axiosApi
						.getById(vm.route, id)
						.then((r) => {
							this.$respuesta(vm, r, 3);

							Swal.close();
						})
						.catch(function (error) {
							this.$respuesta(vm, error, 3, true);
						});
				} else {
					Swal.alertError(
						"No se puede editar el mensaje!",
						"El mensaje ya fue leido o eliminado"
					);
				}
			},

			async save() {
				let vm = this;
				let ruta = "noticias";

				if (vm.$refs.form.validate()) {
					if (vm.editedIndex > -1) {
						Swal.alertGetInfo("Actualizando información");

						await vm.$axiosApi
							.put(ruta, vm.editedIndex, vm.editedItem)
							.then((r) => {
								vm.$respuesta(vm, r, 2, false, false);
								this.initialize();
							})
							.catch((e) => {
								vm.$respuesta(vm, e, 2, true);
							});
					} else {
						Swal.alertGetInfo("Registrando información");

						await vm.$axiosApi
							.post(ruta, vm.editedItem)
							.then((r) => {
								vm.$respuesta(vm, r, 1, false, false);
								this.initialize();
							})
							.catch((e) => {
								vm.$respuesta(vm, e, 1, true);
							});
					}
					Swal.close();
					vm.$close();
				}
			},
			initialize() {
				let vm = this;
				let ruta = "noticias";

				vm.$axiosApi
					.get(ruta)
					.then((r) => {
						if (r.data.data.length > 0) {
							vm.desserts = r.data;
							vm.noticias = r.data.data;
						}
					})
					.catch(function (error) {
						vm.snackbar = true;
						vm.text = "Error al obtener datos. Error: " + error;
						vm.color = "error";
					});
			},
		},
		mounted() {
			console.log("Componente " + this.baseUrl + " creado");
		},
	};
</script>
